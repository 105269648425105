export default function MentionLegale() {
  return (
    <section className="legale">
      <h1>Mentions Légales</h1>
      <p>Le site internet www.salaisonsdelabreche.com est la propriété de :</p>
      <p>SAS Salaisons de la Brèche</p>
      <div className="salaison_info">
        <em>Salaisons de la Brèche</em>
        <p>SAS au Capital de 600 000 Euros 7 Rue Claude Brosse</p>
        <p>42140 Chazelles sur Lyon - France Tél : 04 77 54 38 55</p>
        <p>contact@salaisonsdelabreche.com N° SIRET 382 791 069 00042</p>
        <p>RCS: 382791069 - Saint Etienne</p>
        <p>N° TVA: FR16 382791069</p>
        <p>Code APE : 1013A</p>
        <p>N° agrément sanitaire : FR 42.059.001CE</p>
      </div>
      <div className="info_legales">
        <h2>INFORMATIONS LÉGALES :</h2>
        <p>
          Ledit site internet est protégé au titre des droits de propriété
          intellectuelle.
          <br /> La structure générale ainsi que les logiciels, textes, images
          animées ou non et tous les autres éléments composant le site sont la
          propriété exclusive des Salaisons de la Brèche.
          <br /> Toute représentation totale ou partielle de ce site par quelle
          que personne que ce soit, sans l’autorisation expresse des Salaisons
          de la Brèche est interdite et constituerait une contrefaçon
          sanctionnée par les articles L. 335-2 et suivants du Code de la
          propriété intellectuelle.
        </p>
      </div>
      <div className="o2switch">
        <h2>O2SWITCH</h2>
        <p>
          Le site salaisons de la brèche est hébergé par l’entreprise o2switch :
        </p>
        <p>
          Dont le siège est situé à l’adresse suivante : Chemin DES PARDIAUX
          63000 CLERMONT-FERRAND
        </p>
        <p>Téléphone : 04 44 44 60 40</p>
      </div>
      <div className="cookies">
        <h2>LES COOKIES :</h2>
        <p>
          Le site www.salaisonsdelabreche.com utilise des cookies.
          <br /> Les informations collectées par les cookies sont uniquement et
          strictement destinées au bon fonctionnement du site et ne sont jamais
          transmises à des tiers.
          <br /> Ce cookie ne nous permet pas de vous identifier. Si vous ne les
          acceptez pas vous ne pourrez pas naviguer correctement sur le site.
          <br /> La durée de conservation de ces informations dans votre
          ordinateur est de un an maximum. Il permet de stocker des informations
          relatives à votre navigation sur notre site (les pages que vous avez
          consultées, la date et l’heure de la consultation, etc.).
          <br /> Nous pourrons lire ces informations lors de vos prochaines
          visites. Vous pouvez vous opposer à l’enregistrement des cookies en
          paramétrant votre navigateur.
        </p>
      </div>
      <div className="legale_contact">
        <h2>CONTACT</h2>
        <p>
          Pour tout signalement de contenus ou d’activités illicites,
          l’utilisateur peut contacter l’éditeur à l’adresse suivante :<br />
          <em>contact@salaisonsdelabreche.com</em>
        </p>
      </div>
    </section>
  );
}
