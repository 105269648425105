import familleProst from "../images/SALAISONS DE LA BRECHE - Famille Prost.jpg";

export default function NotreHistore() {
  return (
    <section className="notre_histoire">
      <div className="notre_histoire_left_right">
        <div className="notre_histoire_left">
          <img
            src={familleProst}
            alt="La salaison familiale de 3 générations"
          />
          <div className="notre_histoire_left_img_desc">
            <div className="notre_histoire_left_img_desc_p1">
              <p>LE PAPY</p>
              <p>LE PAPA</p>
              <p>LA PETITE-FILLE</p>
            </div>
            <div className="notre_histoire_left_img_desc_p2">
              <p>
                PROST Paul, <br /> Fondateur & Président
              </p>
              <p>
                PROST Yves, <br />
                <span>Directeurs Généraux de la Salaison</span>
              </p>
              <p>PROST Salomé</p>
            </div>
          </div>
        </div>
        <div className="notre_histoire_right">
          <h1>NOTRE HISTOIRE</h1>
          <div className="notre_histoire_right-desc">
            <h2>LES SALAISONS DE LA BRÈCHE</h2>
            <h3>
              Situé à Chazelles-sur-Lyon,
              <br />
              dans la Loire et à deux pas du Rhône,
              <br />
              <span>Les Salaisons de la Brèche</span> est une entreprise
              familiale <br />
              Qui perpétue son savoir-faire depuis 3 générations.
              <br />
              <br />
              Spécialisée dans la fabrication de saucissons,
              <br />
              Notre salaison propose une large gamme <br />
              de Saucissons authentiques issus de recettes traditionnelles.
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
}
